<template>
    <div>
        요청<br>
        자유게시판
        <div v-html="test">
        </div>
        <div contentEditable="true">test</div>
    </div>
</template>
<script>


export default {
    data(){
        return{
            test:`<div>tests<br>
            asd</div>`,
        }
    },
    methods:{
        add(){
            this.buttons='testmy'
        }
    },
    components: {
        // SignUp,
    },
}
</script>

